<template>
  <div class="dp-header">
    <button
      v-if="isActiveBackButton && !!headerBackButtonTo"
      type="button"
      class="dp-header-button-back"
      v-ripple="$utilities.getRippleOptions('light')"
      @click="onClickBackButton">
      <Icon name="dpArrowLeftR" :width="16" :height="16" color="dpBlack01" />
    </button>

    <h1 class="dp-header-title">{{ pageTitle }}</h1>

    <button
      v-if="isActiveCloseButton"
      type="button"
      class="dp-header-button-close"
      v-ripple="$utilities.getRippleOptions('light')"
      @click="onClickCloseButton">
      <Icon name="dpClose" :width="18" :height="18" color="dpBlack01" />
    </button>
  </div>
</template>

<script>
import { useUserAgent } from '@/composables/useUserAgent';
import {
  defineComponent,
  ref,
  computed,
  watch,
  inject,
  onBeforeUnmount
} from '@vue/composition-api';
export default defineComponent({
  setup: (props, context) => {
    const setHeaderTitle = inject('setHeaderTitle');
    const { isiOS15 } = useUserAgent();
    const pageTitle = ref('');

    // 戻る（<）ボタンの遷移先（name）
    const headerBackButtonTo = ref('');

    // 戻る（<）ボタンを表示させるフラグ
    const isActiveBackButton = computed(
      () => context.root.$route.meta?.isActiveHeaderBackButton || false
    );

    // 閉じる（X）ボタンを表示させるフラグ
    const isActiveCloseButton = computed(
      () => context.root.$route.meta?.isActiveHeaderCloseButton || false
    );

    const onClickBackButton = async () => {
      const isBackFromBrewingPage = !!window.localStorage.getItem(
        'BackFromBrewingPage'
      );

      if (!isiOS15 && !isBackFromBrewingPage) context.root.$router.go(-1);

      // NOTE: iOS 15 Safariへの対応
      if (!headerBackButtonTo.value) return;

      await context.root.$router
        .push({ path: headerBackButtonTo.value })
        .catch((error) => {
          console.error(error);
        });
    };

    // 「閉じる」ボタン選択時の処理
    const onClickCloseButton = async () => {
      removeHistory();
      window.location.href = context.root.$customUrlScheme.close();
    };

    // ローカルストレージへ格納している戻り先を全て削除
    const removeHistory = async () => {
      const STORED_BACK_TO_PAGE_NAMES = [
        'BackFromProRecipe',
        'BackFromProRecipeByCapsuleId',
        'BackFromProRecipeDetail',
        'BackFromTasteMap',
        'BackFromBrewingPage'
      ];
      await STORED_BACK_TO_PAGE_NAMES.forEach((pageName) => {
        window.localStorage.removeItem(pageName);
      });
    };

    watch(
      () => context.root.$route,
      () => {
        if (!context.root.$route?.meta.isActiveDetailTitle) setHeaderTitle();
      }
    );

    const setTitle = (title) => {
      pageTitle.value = title || context.root.$route.meta?.title || '';
    };

    const setBackTo = (path) => {
      headerBackButtonTo.value = path === '/' ? '' : path;
    };

    removeHistory();
    setTitle();

    onBeforeUnmount(() => {
      removeHistory();
    });

    return {
      pageTitle,
      isActiveCloseButton,
      isActiveBackButton,
      onClickBackButton,
      onClickCloseButton,
      setTitle,
      setBackTo,
      headerBackButtonTo,
      isiOS15
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-header {
  @include mixin.dpDefaultText;

  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  grid: auto-flow / 1fr;
  gap: 8px;
  padding: 4px 64px;
  background-color: variables.$dpWhite01;
  width: 100%;
  min-width: 320px;
  height: 48px;
  border-bottom: 1px solid variables.$dpGreyEa;
  overflow: hidden;
}

.dp-header-title {
  @include mixin.clamp(1);

  font-size: 16px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
}

.dp-header-button {
  @mixin dp-header-button {
    z-index: 1;
    position: absolute;
    display: grid;
    grid: auto-flow / 64px;
    place-items: center;
    height: 48px;
    line-height: 1;
  }

  &-back {
    @include dp-header-button;

    left: 0;
  }

  &-close {
    @include dp-header-button;

    right: 0;
  }
}
</style>
