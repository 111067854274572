var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dp-header" }, [
    _vm.isActiveBackButton && !!_vm.headerBackButtonTo
      ? _c(
          "button",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple",
                value: _vm.$utilities.getRippleOptions("light"),
                expression: "$utilities.getRippleOptions('light')",
              },
            ],
            staticClass: "dp-header-button-back",
            attrs: { type: "button" },
            on: { click: _vm.onClickBackButton },
          },
          [
            _c("Icon", {
              attrs: {
                name: "dpArrowLeftR",
                width: 16,
                height: 16,
                color: "dpBlack01",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("h1", { staticClass: "dp-header-title" }, [
      _vm._v(_vm._s(_vm.pageTitle)),
    ]),
    _vm.isActiveCloseButton
      ? _c(
          "button",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple",
                value: _vm.$utilities.getRippleOptions("light"),
                expression: "$utilities.getRippleOptions('light')",
              },
            ],
            staticClass: "dp-header-button-close",
            attrs: { type: "button" },
            on: { click: _vm.onClickCloseButton },
          },
          [
            _c("Icon", {
              attrs: {
                name: "dpClose",
                width: 18,
                height: 18,
                color: "dpBlack01",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }