export const useUserAgent = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const isiOS = /iphone|ipad|ipod/.test(userAgent);
  const isiOS15 = isiOS && /os 15/.test(userAgent);
  const isAndroid = /android/.test(userAgent);

  const isChrome = /chrome/.test(userAgent);
  const isSafari = /safari/.test(userAgent) && !isChrome;

  return {
    isiOS,
    isiOS15,
    isAndroid,

    isChrome,
    isSafari
  };
};
